@use 'colors.scss';

.donation-progress {
    position: absolute;
    width: 1200px;
    height: 80px;
    left: 360px;
    opacity: 0;
    .donation-totals {
        position: absolute;
        right: 0;
        font-family: 'teko';
        color: #FFF;
        filter: drop-shadow(0px 0px 2px #11111155);
        font-size: 24px;
        top: -30px;
    }
    .donation-flair {
        position: absolute;
        left: 0;
        top: -30px;
        
        color: #FFF;
        
        font-size: 24px;
        font-family: 'teko';
        .flair-objective {
            display: inline;
            color: colors.$donation-flair-objective;
            font-family: 'teko-light';
        }
    }
    .donation-bar-size {
        height: 35px;
        width: 1200px;
    }
    .donation-bar-container {
        bottom: -40px;
        position: absolute;
        overflow: hidden;
    }
    .donation-border {
        background: url(../resources/donation-bar/donation-goal-border.png);
        z-index: 10;
        position: absolute;
    }
    .donation-background {
        background: url(../resources/donation-bar/donation-goal-bg.png);
        z-index: 0;
        position: absolute;
    }
    .donation-bar-progressbar {
        width: 1192px;
        height: 23px;
        z-index: 5;
        background: url(../resources/donation-bar/donation-goal-bar.png);
        position: absolute;
        top: 6px;
        left: -1192px;
        .percent {
            position: absolute;
            right: 8;
            top: -1px;

            font-family: 'teko';
            color: colors.$incentive-donation-percent-big;
            font-size: 20px;
        }
    }
}

#donation-feedback {
    position: absolute;
    // border: 5px solid red;
    width: 200px;
    height: 250px;
    top: 1093px;
    left: 850px;
    .donation {
        font-family: teko;
        position: absolute;
        font-size: 24px;
        color: #f3f3f3;
        @include colors.stroke(#11111155, 2px);

    }
    .donation-l {
        color: colors.$main-accent;
    }
    .donation-xl {
        color: colors.$infobar-accent;
    }
    .donation-xxl {
        color: gold;
    }
}